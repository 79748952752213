import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import {
  FontIcon,
  IconButton,
  Modal,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import "./index.css";
import { useTranslation } from "react-i18next";
import { colorPallete } from "../../../assets/color";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import ShareWhiteIcon from "../../../assets/Icons/svg/shareWhiteIcon";
import { shareDocument } from "../../../modules/redux/Documents/document";
import Loader from "../../Loader";
import CrossIcon from "../../../assets/Icons/svg/crossIcon";
import ToggleButtonGroup from "../MultiShareModal/toggleButton";
import SelectedDocuments from "../MultiShareModal/SelectedDocument";
import EmailTextBox from "./EmailTextBox";
import { Document, UserModel } from "../../../models/index";
import DocumentTrackCard from "../MultiShareModal/DocumentTrackCard";
import ShareDisabledIcon from "../../../assets/Icons/svg/shareDisabled";
import { getFullName } from "../../../utils";

export type DocumentWithTrack = {
  document: Document;
  isTracked: boolean;
  includePdf: boolean;
};
interface UserEmail {
  userName: string;
  email: string;
}

const headerStackStyles = {
  root: {
    padding: "12px 20px",
    color: colorPallete.black,
    alignItems: "center",
  },
};

const NewShareModal: FC<any> = (props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState(false);
  const [subjectError, setSetsubjectError] = useState(false);
  const [subject, setSubject] = useState(String(t("review_doc")));
  const [notes, setNotes] = useState(String(t("for_your_review")));
  const [projectId, setProjectId] = useState();
  const [notesError, setNotesError] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [toEmails, setToEmails] = useState<Set<string>>(new Set());
  const [selectedId, setSelectedId] = useState(0);
  const [shareTrackModal, setShareTrackModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const shareDocumentState = useAppSelector((state) => state.shareDocument);
  const [documentWitTrackChanges, setDocumentWithTrackChanges] = useState<
    DocumentWithTrack[]
  >([]);
  const [suggestions, setSuggestions] = useState<UserEmail[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string>("");
  const [userList, setUserList] = useState<UserEmail[]>([]);
  const [isSuggestionFocused, setIsSuggestionFocused] = useState(false);
  const [documentCount, setDocumentCount] = useState<number>(0);
  const workingGroupState = useAppSelector((state) => state.getWorkingGroup);

  function getUserList(list: UserModel[]) {
    let updatedArray: UserEmail[] = [];
    list.forEach((user: UserModel) => {
      let obj = {
        userName: getFullName(user),
        email: user.email,
      };
      updatedArray.push(obj);
    });
    return updatedArray;
  }

  useEffect(() => {
    if (workingGroupState.data && workingGroupState.data.length > 0) {
      setUserList(getUserList(workingGroupState.data));
    }
  }, [workingGroupState]);

  const modalStyles = {
    root: {
      background: "rgba(0, 0, 0, 0.5)",
    },
    main: {
      borderRadius: "8px",
      backgroundColor: "#ecf0f9",
      minWidth: shareTrackModal ? "490px" : "758px",
      width: "758px",
    },
  };

  function setEmails(shareDetails: any, fileType?: string) {
    const updatedList = new Set<string>();
    if (selectedId === 0) setToEmails(updatedList);
    else if (selectedId === 1 && shareDetails?.reply) {
      if (fileType && fileType === "sent") {
        setToEmails(updatedList);
      } else {
        updatedList.add(shareDetails?.reply?.email);
        setToEmails(updatedList);
      }
    } else if (selectedId === 2) {
      if (shareDetails?.replyAll?.length > 0) {
        const replyAll: string[] = [];
        shareDetails?.replyAll.forEach((user: UserModel) => {
          replyAll.push(user.email);
        });
        setToEmails(new Set(replyAll));
      }
    }
  }

  function getPotentialRecipients() {
    setError(false);
    setMessage("");
    if (props?.selectedDocuments && props?.selectedDocuments.length === 1) {
      if (props?.selectedDocuments[0].fileType !== "draft") {
        setEmails(
          props?.selectedDocuments[0].shareDetails,
          props?.selectedDocuments[0].fileType
        );
      }
    } else {
      setEmails(props?.shareDetails);
    }
  }

  useEffect(() => {
    getPotentialRecipients();
  }, [props, selectedId]);

  useEffect(() => {
    if (props.projectId) {
      setProjectId(props.projectId);
    }
  }, [props.projectId]);

  useEffect(() => {
    let docsArray = props.selectedDocuments.map((doc: Document) => {
      let obj: DocumentWithTrack = {
        document: doc,
        isTracked: true,
        includePdf: doc.pdfDetails?.url ? true : false,
      };
      return obj;
    });
    setDocumentWithTrackChanges(docsArray);
  }, [props.selectedDocuments]);

  useEffect(() => {
    if (documentWitTrackChanges.length !== 0) {
      let count = 0;
      documentWitTrackChanges.forEach((item) => {
        if (item.document.pdfDetails?.url) {
          count++;
        }
      });
      setDocumentCount(count);
    }
  }, [documentWitTrackChanges]);

  const onChangeMultiLine = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setNotesError(false);
    setNotes(newValue || "");
  };

  const onChangeSubjectTextField = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      setSetsubjectError(false);
      setSubject(newValue || "");
    },
    []
  );

  const handleShareTrackChanges = () => {
    let documentDetails: {
      documentId: number;
      cleanMeta: boolean;
      driveItemId: string | undefined;
      includePDF: boolean;
    }[] = [];
    documentWitTrackChanges.forEach((documentWithTrack: DocumentWithTrack) => {
      let docDetail = {
        documentId: documentWithTrack.document.documentId,
        cleanMeta: documentWithTrack.isTracked,
        driveItemId: documentWithTrack.document.driveItemId,
        includePDF: documentWithTrack.includePdf,
      };
      documentDetails.push(docDetail);
    });
    let data = {
      projectId: props?.selectedDocuments[0]?.projectId,
      emailList: Array.from(toEmails),
      documentDetails: documentDetails,
      subject: subject,
      emailNotes: notes,
    };
    setModal(true);
    dispatch(shareDocument(data))
      .then((res: any) => {
        setErrorMessage("shared_success");
        window.clarity("event", "Document Share Initiated");
        setTimeout(() => {
          if (res) {
            window.location.reload();
            disable();
          }
        }, 2000);
      })
      .catch(() => {
        setErrorMessage("something_went_wrong");
        setTimeout(() => {
          disable();
        }, 2000);
      });
  };

  const share = () => {
    if (toEmails.size === 0) {
      setError(true);
      setMessage("Add Emails");
    } else if (toEmails.size === 0) {
      setError(true);
      setMessage("Press Enter to Save");
    }
    if (notes.length === 0) {
      setNotesError(true);
    }
    if (subject.length === 0) {
      setSetsubjectError(true);
    }
    console.log(
      "zzz",
      notes.length !== 0,
      toEmails.size !== 0,
      subject.length !== 0,
      props?.selectedDocuments?.length > 0,
      !error
    );
    if (
      notes.length !== 0 &&
      toEmails.size !== 0 &&
      subject.length !== 0 &&
      props?.selectedDocuments?.length > 0 &&
      !error
    ) {
      if (documentWitTrackChanges.some((doc) => !doc.isTracked)) {
        setShareTrackModal(true);
      } else {
        handleShareTrackChanges();
      }
    }
  };
  const loaderJSX = (state: any) => {
    return (
      <VerticalStack className="loader">
        {state.isLoading ? (
          <>
            <Stack className="loading">
              <Loader />
            </Stack>
            <Text
              style={{ padding: "1%", fontSize: "20px", fontFamily: "Poppins" }}
            >
              {t("sharing")}
            </Text>
          </>
        ) : (
          <>
            {errorMessage === "shared_success" ? (
              <FontIcon
                iconName="SkypeCircleCheck"
                style={{
                  fontSize: "40px",
                  backgroundColor: "white",
                  color: colorPallete.btGreen,
                }}
              />
            ) : (
              <Stack style={{ opacity: "0.7" }}>
                <FontIcon
                  iconName="StatusErrorFull"
                  style={{
                    fontSize: "40px",
                    backgroundColor: "white",
                    color: colorPallete.btRed,
                  }}
                />
              </Stack>
            )}
            <Text
              style={{ padding: "1%", fontSize: "20px", fontFamily: "Poppins" }}
            >
              {t(errorMessage)}
            </Text>
          </>
        )}
      </VerticalStack>
    );
  };

  const Title = () => {
    return (
      <HorizontalStack className="title">
        <Text className="titletext">{t("share_document_access")}</Text>
        <Stack
          onClick={() => {
            disable();
          }}
          style={{ cursor: "pointer" }}
        >
          <CrossIcon />
        </Stack>
      </HorizontalStack>
    );
  };

  const disable = () => {
    setNotesError(false);
    setSelectedId(0);
    setError(false);
    setMessage("");
    props.hideModal();
    setModal(false);
    setSelectedEmail("");
  };

  return (
    <Modal
      styles={modalStyles}
      isOpen={props.isModalOpen}
      onDismiss={disable}
      isBlocking={true}
    >
      {modal ? (
        <Stack className="uploadModal">
          <Title />
          {loaderJSX(shareDocumentState)}
        </Stack>
      ) : shareTrackModal ? (
        <>
          <HorizontalStack
            styles={headerStackStyles}
            horizontalAlign="space-between"
          >
            {documentWitTrackChanges.some((doc) => !doc.isTracked) ? (
              <Text
                style={{
                  fontFamily: "Poppins",
                  fontSize: "17px",
                  lineHeight: "26px",
                  fontWeight: 500,
                  color: "#323232",
                  width: "90%",
                }}
              >
                {t("warning_share_track_changes")}
              </Text>
            ) : (
              <Text
                style={{
                  fontFamily: "Poppins",
                  fontSize: "17px",
                  lineHeight: "26px",
                  fontWeight: 500,
                  color: "#323232",
                }}
              >
                {t("warning_clean_document")}
              </Text>
            )}

            <IconButton
              iconProps={{ iconName: "Cancel" }}
              style={{
                color: colorPallete.black,
                backgroundColor: "inherit",
                position: "absolute",
                right: 10,
              }}
              onClick={() => {
                props.hideModal();
                setShareTrackModal(false);
              }}
            />
          </HorizontalStack>
          <VerticalStack
            className="shareTrackContainer"
            style={{
              minHeight: "180px",
              width: "100%",
              padding: "0 1vh 1vh 1vh",
            }}
          >
            <VerticalStack
              style={{
                padding: "15px 25px",
                gap: "12px",
                backgroundColor: "white",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              {documentWitTrackChanges.some((doc) => !doc.isTracked) ? (
                <Text className="shareContentText">
                  {t("share_track_changes")}
                </Text>
              ) : (
                <Text className="shareContentText">
                  {t("clean_document_content")}
                </Text>
              )}

              <VerticalStack className="shareTrackVersions">
                {documentWitTrackChanges.map((item: any, index: number) => {
                  return (
                    <DocumentTrackCard
                      documentWithTrack={item}
                      setDocumentWithTrack={setDocumentWithTrackChanges}
                      length={documentWitTrackChanges.length}
                    />
                  );
                })}
              </VerticalStack>
            </VerticalStack>
            <HorizontalStack
              className="SendButton"
              style={{ padding: "15px 15px", alignSelf: "flex-start" }}
            >
              <Text
                className="Send"
                style={{
                  backgroundColor: documentWitTrackChanges.some(
                    (doc) => !doc.isTracked
                  )
                    ? "#a82c0c"
                    : "#18468F",
                }}
                onClick={handleShareTrackChanges}
              >
                {t("send")}
              </Text>
              <Text
                className="goBack"
                onClick={() => {
                  setShareTrackModal(false);
                }}
                style={{
                  backgroundColor: documentWitTrackChanges.some(
                    (doc) => !doc.isTracked
                  )
                    ? "#f6eae7"
                    : "#E8ECF4",
                }}
              >
                {t("go_back")}
              </Text>
            </HorizontalStack>
          </VerticalStack>
        </>
      ) : (
        <VerticalStack className="container">
          <Title />
          <ToggleButtonGroup
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            getPotentialRecipients={getPotentialRecipients}
          />
          <Stack className="body">
            <HorizontalStack style={{ gap: "8px" }}>
              <VerticalStack
                className="button"
                style={{
                  backgroundColor:
                    toEmails.size === 0 ||
                    subject.length === 0 ||
                    notes.length === 0
                      ? "#EAEAEA"
                      : "#18468F",
                }}
                onClick={share}
              >
                {toEmails.size === 0 ||
                subject.length === 0 ||
                notes.length === 0 ? (
                  <ShareDisabledIcon />
                ) : (
                  <ShareWhiteIcon />
                )}
                <Text
                  className="sendText"
                  style={{
                    color:
                      toEmails.size === 0 ||
                      subject.length === 0 ||
                      notes.length === 0
                        ? "#717070"
                        : "#ffffff",
                  }}
                >
                  {t("send")}
                </Text>
              </VerticalStack>
              <VerticalStack className="textContainer">
                <HorizontalStack
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    backgroundColor: "",
                  }}
                >
                  <HorizontalStack className="textWrapper">
                    <Text className="text">{t("to")}:</Text>
                  </HorizontalStack>
                  <Stack
                    style={{
                      backgroundColor: "",
                      marginLeft: "2.3%",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <EmailTextBox
                      emails={toEmails}
                      setEmails={setToEmails}
                      error={error}
                      setError={setError}
                      message={message}
                      setMessage={setMessage}
                      setSuggestions={setSuggestions}
                      userList={userList}
                      isSuggestionFocused={isSuggestionFocused}
                      setIsSuggestionFocused={setIsSuggestionFocused}
                      selectedEmail={selectedEmail}
                    />
                    {suggestions.length > 0 && (
                      <VerticalStack
                        className="suggestionListContainer"
                        onMouseEnter={() => setIsSuggestionFocused(true)}
                        onMouseLeave={() => setIsSuggestionFocused(false)}
                      >
                        {suggestions.map((user) => (
                          <HorizontalStack
                            key={user.email}
                            onClick={() => setSelectedEmail(user.email)}
                            className="suggestionList"
                          >
                            <VerticalStack>
                              <Text className="suggestionUserName">
                                {user.userName}
                              </Text>
                              <Text className="suggestionUserEmail">
                                {user.email}
                              </Text>
                            </VerticalStack>
                            <Stack className="tick">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M16.6673 5L7.50065 14.1667L3.33398 10"
                                  stroke="#18468F"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Stack>
                          </HorizontalStack>
                        ))}
                      </VerticalStack>
                    )}
                  </Stack>
                </HorizontalStack>
                <HorizontalStack
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    backgroundColor: "",
                  }}
                >
                  <HorizontalStack className="textWrapper">
                    <Text className="text">{t("cc")}:</Text>
                  </HorizontalStack>
                  <Stack
                    style={{
                      backgroundColor: "",
                      marginLeft: "12px",
                      width: "100%",
                    }}
                  >
                    <TextField placeholder={""} disabled />
                  </Stack>
                </HorizontalStack>
                <HorizontalStack
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    backgroundColor: "",
                  }}
                >
                  <HorizontalStack className="textWrapper">
                    <Text className="text">{t("bcc")}:</Text>
                  </HorizontalStack>
                  <Stack
                    style={{
                      backgroundColor: "",
                      marginLeft: "12px",
                      width: "100%",
                    }}
                  >
                    <TextField placeholder={""} disabled />
                  </Stack>
                </HorizontalStack>
                <HorizontalStack
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    backgroundColor: "",
                  }}
                >
                  <HorizontalStack className="textWrapper1">
                    <Text className="text">{t("Subject")}:</Text>
                  </HorizontalStack>
                  <Stack
                    style={{
                      backgroundColor: "",
                      marginLeft: "2.3%",
                      width: "100%",
                    }}
                  >
                    <TextField
                      placeholder={String(t("add_subject"))}
                      style={{ border: "none", fontFamily: "Poppins" }}
                      underlined
                      onChange={onChangeSubjectTextField}
                      value={subject}
                      borderless={true}
                      styles={{
                        root: {
                          width: "100%",
                          borderBottom: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
                        },
                        fieldGroup: {
                          // backgroundColor:"yellow"
                          fontFamily: "Poppins",
                          fontWeight: "500",
                          color: "#323232",
                        },
                        wrapper: {
                          border: "none",
                        },
                      }}
                      disabled={props.fileType === "sent"}
                    />
                    {subjectError && (
                      <Text
                        style={{
                          color: colorPallete.btRed,
                          marginLeft: "1%",
                        }}
                      >
                        {t("subject_required")}
                      </Text>
                    )}
                  </Stack>
                </HorizontalStack>
              </VerticalStack>
            </HorizontalStack>
            <div
              style={{
                borderTop: "1px solid #E8ECF4",
                borderBottom: "1px solid #E8ECF4",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <SelectedDocuments
                documentCount={documentCount}
                setSelectedDocuments={props?.setSelectedDocuments}
                documentWithTracks={documentWitTrackChanges}
                setDocumentWithTrackChanges={setDocumentWithTrackChanges}
              />
            </div>
            <Stack className="bodyTextWrapper">
              {notesError && (
                <Text
                  style={{
                    color: colorPallete.btRed,
                    marginLeft: "1%",
                  }}
                >
                  {t("notes_required")}
                </Text>
              )}
              <TextField
                resizable={false}
                onChange={onChangeMultiLine}
                borderless
                multiline={true}
                autoAdjustHeight
                id="message"
                value={notes}
                style={{
                  minHeight: "200px",
                  fontFamily: "Poppins",
                }}
                disabled={props.fileType === "sent"}
                placeholder={String(t("add_message"))}
              />
            </Stack>
          </Stack>
        </VerticalStack>
      )}
    </Modal>
  );
};

export default NewShareModal;
